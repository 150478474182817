import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Card, CardSelectable } from '../Card';
import { Flex } from '../Flex';
import { Box } from '../Box';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "card"
    }}>{`Card`}</h1>
    <p>{`Card is a `}<a parentName="p" {...{
        "href": "/components/Box"
      }}>{`Box`}</a>{` with a bit of extra styling, it can be used for a list of items, or to highlight an important piece of UI.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Card} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <p>{`Box implements the styled-system props for:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#space"
        }}>{`space`}</a>{` (only use full-length props. e.g. `}<inlineCode parentName="li">{`paddingBottom`}</inlineCode>{`, not `}<inlineCode parentName="li">{`pb`}</inlineCode>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#layout"
        }}>{`layout`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#color"
        }}>{`color`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#border"
        }}>{`border`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#flex"
        }}>{`flexbox`}</a></li>
    </ul>
    <p>{`It add a prop for `}<inlineCode parentName="p">{`elevation`}</inlineCode>{` that controls the size of the drop shadow. `}<em parentName="p">{`A number between 0 an 5 - defaults to 3/base'`}</em></p>
    <Playground __position={1} __code={'<Card>\n  <Heading as=\"h3\">Card titles can be set with your normal Heading</Heading>\n  <Paragraph>Two</Paragraph>\n</Card>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Card,
      CardSelectable,
      Flex,
      Box,
      Heading,
      Paragraph,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Card mdxType="Card">
    <Heading as="h3" mdxType="Heading">Card titles can be set with your normal Heading</Heading>
    <Paragraph mdxType="Paragraph">Two</Paragraph>
  </Card>
    </Playground>
    <h2 {...{
      "id": "elevation"
    }}>{`Elevation`}</h2>
    <Playground __position={2} __code={'<Box bg=\"neutral.lightest\" padding=\"xlarge\">\n  <Card elevation=\"0\" marginBottom=\"xlarge\">\n    <Heading as=\"h3\">No shadow</Heading>\n    <Paragraph>Generally avoid, only use on colored backgrounds.</Paragraph>\n  </Card>\n  <Card elevation=\"1\" marginBottom=\"xlarge\">\n    <Heading as=\"h3\">xsmall Shadow</Heading>\n    <Paragraph>Usually you\'ll use the default</Paragraph>\n  </Card>\n  <Card elevation=\"2\" marginBottom=\"xlarge\">\n    <Heading as=\"h3\">Small Shadow</Heading>\n    <Paragraph>Usually you\'ll use the default</Paragraph>\n  </Card>\n  <Card elevation=\"3\" marginBottom=\"xlarge\">\n    <Heading as=\"h3\">Base Shadow</Heading>\n    <Paragraph>This is the default</Paragraph>\n  </Card>\n  <Card elevation=\"4\" marginBottom=\"xlarge\">\n    <Heading as=\"h3\">Large Shadow</Heading>\n    <Paragraph>Usually you\'ll use the default</Paragraph>\n  </Card>\n  <Card elevation=\"5\" marginBottom=\"xlarge\">\n    <Heading as=\"h3\">XLarge Shadow</Heading>\n    <Paragraph>Usually you\'ll use the default</Paragraph>\n  </Card>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Card,
      CardSelectable,
      Flex,
      Box,
      Heading,
      Paragraph,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      <Box bg='neutral.lightest' padding='xlarge' mdxType="Box">
  <Card elevation='0' marginBottom='xlarge' mdxType="Card">
    <Heading as="h3" mdxType="Heading">No shadow</Heading>
    <Paragraph mdxType="Paragraph">Generally avoid, only use on colored backgrounds.</Paragraph>
  </Card>
        <Card elevation="1" marginBottom="xlarge" mdxType="Card">
  <Heading as="h3" mdxType="Heading">xsmall Shadow</Heading>
  <Paragraph mdxType="Paragraph">Usually you'll use the default</Paragraph>
        </Card>
        <Card elevation="2" marginBottom="xlarge" mdxType="Card">
  <Heading as="h3" mdxType="Heading">Small Shadow</Heading>
  <Paragraph mdxType="Paragraph">Usually you'll use the default</Paragraph>
        </Card>
        <Card elevation="3" marginBottom="xlarge" mdxType="Card">
  <Heading as="h3" mdxType="Heading">Base Shadow</Heading>
  <Paragraph mdxType="Paragraph">This is the default</Paragraph>
        </Card>
        <Card elevation="4" marginBottom="xlarge" mdxType="Card">
  <Heading as="h3" mdxType="Heading">Large Shadow</Heading>
  <Paragraph mdxType="Paragraph">Usually you'll use the default</Paragraph>
        </Card>
        <Card elevation="5" marginBottom="xlarge" mdxType="Card">
  <Heading as="h3" mdxType="Heading">XLarge Shadow</Heading>
  <Paragraph mdxType="Paragraph">Usually you'll use the default</Paragraph>
        </Card>
      </Box>
    </Playground>
    <h1 {...{
      "id": "cardselectable"
    }}>{`CardSelectable`}</h1>
    <Props of={CardSelectable} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <Playground __position={4} __code={'<Flex bg=\"neutral.lightest\" padding=\"xlarge\">\n  <CardSelectable marginRight=\"xlarge\">\n    <Heading as=\"h3\">Card titles can be set with your normal Heading</Heading>\n    <Paragraph>Two</Paragraph>\n  </CardSelectable>\n  <CardSelectable marginRight=\"xlarge\" selected>\n    <Heading as=\"h3\">Card titles can be set with your normal Heading</Heading>\n    <Paragraph>Two</Paragraph>\n  </CardSelectable>\n  <CardSelectable>\n    <Heading as=\"h3\">Card titles can be set with your normal Heading</Heading>\n    <Paragraph>Two</Paragraph>\n  </CardSelectable>\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Card,
      CardSelectable,
      Flex,
      Box,
      Heading,
      Paragraph,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex bg="neutral.lightest" padding="xlarge" mdxType="Flex">
    <CardSelectable marginRight="xlarge" mdxType="CardSelectable">
      <Heading as="h3" mdxType="Heading">Card titles can be set with your normal Heading</Heading>
      <Paragraph mdxType="Paragraph">Two</Paragraph>
    </CardSelectable>
    <CardSelectable marginRight="xlarge" selected mdxType="CardSelectable">
      <Heading as="h3" mdxType="Heading">Card titles can be set with your normal Heading</Heading>
      <Paragraph mdxType="Paragraph">Two</Paragraph>
    </CardSelectable>
    <CardSelectable mdxType="CardSelectable">
      <Heading as="h3" mdxType="Heading">Card titles can be set with your normal Heading</Heading>
      <Paragraph mdxType="Paragraph">Two</Paragraph>
    </CardSelectable>
  </Flex>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      